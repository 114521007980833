// Replicate the behavior in Quarto's rendered output
// https://github.com/quarto-dev/quarto-cli/blob/v1.7.13/src/resources/formats/html/_quarto-rules-copy-code.scss

:not(.no-code-copy)>.highlight:not(.no-code-copy)>pre {

  position: relative;

  .code-copy-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    margin-top: 5px;
    margin-right: 5px;
    background-color: transparent;
    z-index: 3;

    &>.bi::before {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      content: "";
      vertical-align: -0.125em;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/><path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/></svg>');
      background-repeat: no-repeat;
      background-size: 1rem 1rem;
    }

    &.code-copy-button-checked>.bi::before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/></svg>');
    }

  }

}