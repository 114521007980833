.location {
  padding: 6rem 50px 6rem 50px;

  & div:nth-of-type(1) {
    @media (max-width: 768px) {
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 3rem 0;
  }

  .card {
    border: 0;
    background-color: $secondary;

    .card-body {
      background-color: $secondary;
      padding: 35px 50px 35px 80px;

      @media (max-width: 768px) {
        padding: 35px 35px 0 35px;

        .flex-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h3,
        p {
          text-align: center !important;
        }

        p {
          padding: 10px 0 30px 0;
        }
        button {
          padding: 7px 0 7px 0;
          margin: 0;
          width: 144px;
        }
      }
    }

    .card-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;

      @media (max-width: 768px) {
        padding: 2rem;
      }
    }
  }
}