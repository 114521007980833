.workshops {
  .container-01 {
    padding: 0 50px 3rem 50px;
    @media (max-width: 768px) {
      padding: 0 30px 2rem 30px !important;
    }

    .card {
      background: linear-gradient(127.45deg, $primary 51.42%, #8adeff 99.15%);
      border: 0;
      border-radius: 0;

      p,
      h3,
      h5,
      a {
        color: white;
        padding: 0;
      }

      h3 {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: normal !important;
        font-weight: 700;
        padding-bottom: 5px;

        @media (min-width: 1270px) {
          font-size: 1.4rem;
        }

        @media (min-width: 1540px) {
          font-size: 1.85rem;
        }
      }

      a > img {
        width: auto;
        object-fit: contain;
      }

      p {
        margin: 0;
      }

      img {
        max-height: 40vh !important;
        object-fit: cover;
        border-radius: 0;
      }

      .card-body {
        height: 40vh;
        max-height: 40vh;
        margin: auto;
        padding: 20px !important;
        justify-content: center;

        .ws-inline {
          margin: 0;
          padding-top: 0.7rem;

          a.arrow-ws {
            margin-left: 0.5rem;
            transform: translateY(25%);
          }
        }
      }

      .picture-1 {
        object-fit: cover;
        object-position: 95% center;
      }
    }
  }
}

// CAROUSEL

.carousel-item {
  // height: 35vh;
  min-height: 35vh;
  // min-height: 200px;
  background: $primary;
  position: relative;
  color: white;

  & > div {
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .carousel-caption {
    h5,
    p {
      color: white;
      margin: 10px;
      padding: 0;
    }
  }
}

button.owl-prev, button.owl-next, .workshops.owl-nav{
  display: none!important;
}
.cards-mobile {
  padding: 4rem 30px 3rem 30px !important;

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px !important;
  }
}
body > section.workshops > div > div > div > div.owl-dots{
  padding-top: 0.8rem;
}
//
//

// .owl-carousel .owl-nav .owl-prev:before {
//   // fa-chevron-left
//   content: "\f053";
//   margin-right: 10px;
// }
// .owl-carousel .owl-nav .owl-next:after {
//   //fa-chevron-right
//   content: "\f054";
//   margin-right: 10px;
// }
