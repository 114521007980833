.grid-main {
  .box-1 {
    .grid-box.style_1 {
      margin: 0;

      .grid-content {
        position: relative;
        overflow: hidden;
        height: 100%;

        & > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0;
        }

        h5,
        h3,
        p,
        .learn-more {
          padding: 0 30px 0 30px;
        }

        .learn-more {
          padding-bottom: 20px;
        }

        h5 {
          padding-top: 10px;
          padding-bottom: 0.5rem;
          font-size: 0.8rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: -0.3pt !important;
          color: $title;
          @media (min-width: 1400px) {
            font-size: 0.9rem !important;
          }
          margin: 0;
        }
        p {
          font-size: 0.8rem !important;
          margin: 0;
          padding-bottom: 10px;
          line-height: 1.5rem;
          overflow-wrap: break-word;
          color: $body;

          @media (min-width: 1400px) {
            font-size: 1rem !important;
          }
        }

        h3 {
          font-size: 1.3rem !important;
          text-transform: uppercase;
          letter-spacing: -0.3pt !important;
          font-weight: 700;
          color: $title;
          padding-bottom: 7px;

          @media (min-width: 1400px) {
            font-size: 1.85rem !important;
          }
        }

        img {
          object-fit: cover;
          transition: transform 1s ease;
        }
        & img:hover {
          transform: scale(1.05);
          -webkit-filter: brightness(50%);
          transition: 1s;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .carousel-inner {
      .carousel-caption {
        margin: 0;
        padding: 0;
      }
      p,
      h5 {
        margin: 0 !important;
        padding: 1rem 0 0 0 !important ;
      }
      h5 {
        font-size: medium;
      }
      p {
        line-height: 1.2rem !important;
      }
    }
    .cards-mobile-none {
      display: none !important;
    }
  }
}
