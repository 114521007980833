//   Subscribe Newsletter
//
.subscribe-nl {
  z-index: 150;

  .container-fluid {
    background-color: $secondary;
    min-height: 33vh;
    padding: 0 10rem 0 10rem;
    @media (max-width: 768px) {
      padding: 0 30px 0 30px;
    }

    .card {
      border: 0;
      background-color: $secondary;
    }
  }
  @media (max-width: 768px) {
    div.col-md-5:nth-child(2),
    div.col-md-5:nth-child(2) > div:nth-child(1),
    span.d-block > p:nth-child(2) {
      margin: 0 !important;
    }
    .rows > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}

//
//  END subscribe newsletter
//