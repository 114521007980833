button.button,
a.button {
  --bs-btn-padding-y: 7px;
  --bs-btn-padding-x: 45px;
  --bs-btn-border-radius: 0;
  --bs-btn-font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
  letter-spacing: 0.5pt;

}
