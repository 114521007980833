// Variables for backward-compatibility

// RSc: start
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #008CC3 !default;
$brand-secondary:       #C2CED9 !default;
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$color-course-tech:      #96da75 !default;
$color-course-lvl:       #ffdd66 !default;
$color-course-dur:       #ff9966 !default;
$color-course-bg:        #dcedf5 !default;

//== Course Panels
/* rounded corners of the full bordered panel and concentric corners for the heading/footer */
$panel-course-border:         1px !default;
$panel-course-radius:         15px !default;
$panel-course-inner-radius:   ($panel-course-radius - $panel-course-border) !default;
// RSc: end

$primary: $brand-primary;
$secondary: $brand-secondary;
$info: $brand-info;
