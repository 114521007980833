// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }
// body {
//   height: 100vh;
//   margin: 0;
// }
// html, body {
//   height: 100%;
//   margin: 0;
//   padding: 0;
  
// }

@import "index3dot0/hero";
@import "index3dot0/cards";
@import "index3dot0/data-science";
@import "index3dot0/footer3dot0";
@import "index3dot0/mirai-switzerland";
@import "index3dot0/nav3dot0";
@import "index3dot0/news";
@import "index3dot0/expertise";
@import "index3dot0/sticky-socials";
@import "index3dot0/subscribe-newsletter";
@import "index3dot0/workshop-carousel";
@import "index3dot0/components/buttons";
@import "index3dot0/components/typography";
@import "index3dot0/components/forms";
@import "compatibility";
@import "index3dot0/iframe-size";
@import "index3dot0/code-copy";
@import "index3dot0/other";

@import "bootstrap";

@font-face {
  font-family: "Frutiger LT W01_45 Ligh1475730";
  src: url("../assets/webfonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix");
  src: url("../assets/webfonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix")
      format("eot"),
    url("../assets/webfonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2")
      format("woff2"),
    url("../assets/webfonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff")
      format("woff"),
    url("../assets/webfonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf")
      format("truetype"),
    url("../assets/webfonts/29f3ff8a-1719-4e25-a757-53ee1a1114a5.svg#29f3ff8a-1719-4e25-a757-53ee1a1114a5")
      format("svg");
}
@font-face {
  font-family: "Frutiger LT W01_55 Roma1475738";
  src: url("../assets/webfonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix");
  src: url("../assets/webfonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix")
      format("eot"),
    url("../assets/webfonts/0811514e-6660-4043-92c1-23d0b8caaa2f.woff2")
      format("woff2"),
    url("../assets/webfonts/aeae8cac-ba4d-40d2-bbcf-936f2c7dcfe6.woff")
      format("woff"),
    url("../assets/webfonts/caedf2e6-fbef-4928-b08e-1f975981ae8d.ttf")
      format("truetype"),
    url("../assets/webfonts/7def0e34-f28d-434f-b2ec-472bde847115.svg#7def0e34-f28d-434f-b2ec-472bde847115")
      format("svg");
}
@font-face {
  font-family: "Open Sans";
  src: url("../assets/webfonts/");
  src: url("../assets/webfonts/OpenSans-VariableFont_wdth\,wght.ttf")
    format("truetype");
  // src: url("../assets/webfonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf")
  //   format("truetype");
}

