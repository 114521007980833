//
//  FOOTER
//

footer {
  // min-height: 35vh;
  z-index: 150;
  overflow: hidden !important;
  display: flex;
  background-color: $secondary;

  .container-xxl {
    padding: 0 50px;
    @media (max-width: 768px) {
      padding: 0 30px 0 30px;
    }
    .row-border {
      border-bottom: 1px solid #858585;
    }

    ul {
      li.nav-item a {
        padding: 0 16px 10px 0 !important;
        color: inherit;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        letter-spacing: 1.4px;
      }
    }
    .logo {
      width: auto;
      height: 35px;
    }

    span p {
      font-size: 12px !important;
    }
  }
}
#footer
  > div
  > div.row.d-flex.flex-wrap.justify-content-start.align-items-center.py-4.my-3.row-border
  > div.col-md-9.d-flex.flex-column.justify-content-center
  > ul
  > div:nth-child(2) {
  padding-left: 1.5rem !important;
}

#footer ul li.nav-item a{
  text-transform: capitalize;
}