
.datasci {
  overflow: hidden;
  margin: 0;
  padding: 6rem 50px 6rem 50px;
  gap: 0 !important;

  @media (max-width: 768px) {
    padding: 3rem 30px 3rem 30px;
  }

  .img-datasci > img {
    object-fit: cover;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: 0.4s;

    &:hover {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      transition: 1s;
    }
  }

  .card {
    border: 0;

    .card-body {
      // padding: 35px 50px 35px 80px;

      @media (max-width: 768px) {
        // padding: 35px 30px 0 30px;

        .flex-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h3,
        .learn-more,
        h5 {
          text-align: start;
          // padding: 0 2rem 0 2rem;
          padding: 0;
        }
        .learn-more {
          padding-top: 1rem;
        }

        p {
          text-align: start;
        }
        button {
          padding: 7px 0 7px 0;
          margin: 0;
          width: 144px;
        }
      }
    }

    .card-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;

      @media (max-width: 768px) {
        padding: 1rem 0 0 0;
      }
    }
  }
}



// Padding Data science section
.datasci {
  .text-start {
    p {
      padding-right: 1.5rem;
    }
  }
}
