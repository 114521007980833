
html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
.overflow-y-1 {
  overflow: hidden overlay;
  scroll-behavior: smooth;
}

//

$gray-100: #f8f9fa;
$white: #fff;
$gray-200: #e9ecef;
$gray-300: #f2f2f2;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// My theme colors
$blue: #008cc3;
$blue-btn-hero: #039fdc;

$primary: $blue;
$secondary: $gray-300;
$body: #686868;
$learn-more: #333333;
$title: #333333;

#hero {
  overflow: hidden;
  padding: 90px 50px 0 50px;

  position: relative;
  // background: linear-gradient(
  //     to bottom,
  //     rgba(0, 0, 0, 0.116),
  //     rgba(0, 0, 0, 0.185)
  //   ),
  //   url(/assets/images/img/AdobeStock_631962714_Video_4K_Preview.webm);
  min-height: 100vh;
  background-size: cover;
  background-position: center left;

  // hr {
  //   width: 100px;
  //   margin-left: 0;
  //   background-color: $primary;
  //   height: 6px;
  //   opacity: 100%;
  //   margin-top: 0;
  // }

  @media (max-width: 768px) {
    padding: 3rem 30px 3rem 30px;

    .row {
      padding: 0 !important;
      margin: 0 !important;

      .intros {
        padding: 0 !important;
        margin: 0 !important;
        h4 {
          width: 100%;
          span {
            width: 100%;
          }
        }
      }
    }
  }
}

.intros h1 {
  position: relative;
}

.intros h1::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100px;
  height: 6px;
  background-color: $primary; /* Replace with your desired line color */
}

@media (max-width: 1400px) {
  .hero {
    .container-xxl {
      width: 100%;
    }
  }
}

.hero-video {
  position: absolute;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the entire section */
  z-index: -1; /* Places the video behind other content */
}
#hero .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.116),
    rgba(0, 0, 0, 0.185)
  );
  z-index: 0; /* Places the gradient behind the video */
}

