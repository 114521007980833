body,
a,
p,
ul,
li,
blockquote {
  font-family: "Frutiger LT W01_45 Ligh1475730", Helvetica, Arial, sans-serif;
}

$gray-100: #f8f9fa;
$white: #fff;
$gray-200: #e9ecef;
$gray-300: #f2f2f2;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

// My theme colors
$blue: #008cc3;
$blue-btn-hero: #039fdc;

$primary: $blue;
$secondary: $gray-300;

$body: #555555;
$learn-more: #333333;
$title: #333333;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}
h1,
h2,
h3,
h5 {
  font-stretch: condensed;
}
nav a {
  letter-spacing: 0.4pt;
}

.display-2 {
  margin-bottom: 1rem;
  text-transform: capitalize;

  &--intro {
    display: inline-block;
    font-weight: 700;
  }

  &--description {
    font-size: 1rem;
    display: block;
  }
}

p {
  font-size: 0.875rem;
  margin: auto;
  padding: 0 0 20px 0;
  line-height: 1.5rem;
  overflow-wrap: break-word;
  color: $body;

  @media (min-width: 1540px) {
    font-size: 1rem;
  }
}
ul,
li {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: $body;

  @media (min-width: 1540px) {
    font-size: 1rem;
  }
}
.insight > p {
  font-size: 0.75rem !important;
  margin: auto;
  padding: 5px 3px 10px 10px;
  line-height: 1rem;
}

h1 {
  font-size: calc(30px + (40 - 30) * ((100vw - 300px) / (1600 - 300)));
  color: $title;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: $white;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: -0.3pt !important;
  font-weight: 700 !important;
  padding-bottom: 15px;
  color: $title;

  @media (min-width: 1270px) {
    font-size: 1.7rem;
  }

  @media (min-width: 1540px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 1.5rem;
  text-transform: capitalize;
  letter-spacing: -0.3pt !important;
  font-weight: 500 !important;
  padding-bottom: 15px;
  color: $title;

  @media (min-width: 1270px) {
    font-size: 1.7rem;
  }

  @media (min-width: 1540px) {
    font-size: 2rem;
  }
}

h4 {
  color: $white;
  width: 50%;
  font-size: 1rem;
  letter-spacing: 1.5px;
  word-spacing: 1.5px;
  padding-top: 15px;
  padding-bottom: 20px;
}
h5 {
  font-size: 0.85rem;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: -0.3pt !important;
  color: $title;
  @media (min-width: 1540px) {
    font-size: 1.1rem;
  }
}

h6 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: $title;
  font-weight: 600 !important;
}

span.accent-blue {
  color: $primary;
}

span.learn-more {
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: bold;
  a {
    text-decoration: none;
    color: $learn-more;

    & > img {
      padding-left: 8px;
      width: auto;
      height: 10px;
    }
  }
}

header.wrapper {
  background-color: $primary;
  h1 {
    color: $white;
    font-size: 1.875rem;
    font-stretch: normal;
    padding: 1.3rem 0 !important;
    text-align: center;
    text-transform: uppercase;
    font-family: "Frutiger LT W01_45 Ligh1475730";
    letter-spacing: 0.1em;
  }
  h1.asis {
    text-transform: none;
  }
}


// Blockquotes

$blockquote-border-color: #eeeeee;
$blockquote-font-style: italic;
$blockquote-padding: 1.5rem;

// NOTE: Since bootstrap > 3, custom blockquote styling is done via with class="blockquote"
//       (see https://getbootstrap.com/docs/4.0/migration/#typography) but we still style both
blockquote, .blockquote {
  color: $body;
  padding: ($blockquote-padding / 4) $blockquote-padding;
  font-style: $blockquote-font-style;
  border-left: 5px solid $blockquote-border-color;
}
