
.news {
  // min-height: 100px;
  // height: auto;
  padding: 1rem 50px 1rem 50px;
  margin: 0;
  overflow-y: hidden;

  a {
    text-decoration: none !important;
    // display: flex;
    // flex-wrap: wrap !important;
    // flex-direction: row;

    img {
      display: none !important;
    }
  }

  // div:first-of-type {
  //   height: fit-content;
  // }
  @media (max-width: 768px) {
    padding: 30px;

    .is-text {
      padding-bottom: 1rem;
    }
  }
  .owl-nav {
    display: none !important;
  }

  .item {
    .news-posts-banner {
      border: 0;
      border-radius: 0;
      padding: 0 10px 0 10px;
      margin: 0;

      display: flex;
      p {
        padding: 0;
        line-height: 18px;
      }
      img {
        width: 15;
        height: 10px;
        padding: 0;
        margin: auto;
      }
    }
  }
  @media (max-width: 1400px) {
    .news {
      .container-xxl {
        width: 100%;
      }
    }
  }
  .owl-stage-outer,
  .owl-stage {
    display: inline-flex;

    .owl-item.active {
      border-left: solid 3px $primary;
      @media (max-width: 768px) {
        border-left: 0;
      }
      .item {
        display: flex;
        align-items: center;
      }
    }
  }
}
