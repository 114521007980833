
.google-map{
    @media (max-width: 767px) {
        iframe {
          height: 40vh;
        }
      }
    
    @media (min-width: 768px) and (max-width: 1200px) {
        iframe {
          height: 50vh;
        }
      }
    
    @media (min-width: 992px) {
        iframe {
          height: 60vh;
        }
    }
}
