.parent-expertise {
  // height: 100%;

  display: flex;
  flex-wrap: wrap;

  .expertise {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    background-color: $secondary;
    margin: auto;
    padding: 4rem 40px 4rem 40px;
    @media (max-width: 768px) {
      padding: 2rem 30px 2rem 30px;
    }

    p {
      padding-right: 4rem;
    }
  }

  & > div {
    width: 50%;
    min-height: 40vh;

    @media (min-width: 1540px) {
      max-height: 500px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}