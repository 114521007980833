//
//   STICKY SOCIAL
//
div#socials-pill {
  position: fixed;
  background-color: $primary;
  z-index: 16;
  top: 50%;
  transform: translateY(-38%);
  right: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  line-height: 0 !important; // so the icons do not take up extra vertical space
  gap: 25px;
  padding: 25px 0;

  a {
    color: white;
    font-size: 22px;
    align-self: center;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

//
//  END STICKY SOCIAL
//
//
