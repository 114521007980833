.ws-01 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin-top: 0;
  
    label {
      letter-spacing: 0.025rem;
      color: darken($brand-primary, 10%) !important;
      font-family: "Frutiger LT W01_55 Roma1475738";
      font-weight: 400;
    }
  }
  .ws-02 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  
    label {
      letter-spacing: 0.025rem;
      color: darken($brand-primary, 10%) !important;
      font-family: "Frutiger LT W01_55 Roma1475738";
      font-weight: 400;
    }
  }

  ul.ws-list {
    li.closed {
      opacity: 0.5;
      text-decoration-line: line-through;
    }
  }

  .header-contentbox {
    padding-bottom: 50px;
    margin-bottom: 30px;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      margin-top: 50px;
      background: $brand-secondary;
    }
    padding-bottom: 50px;
    margin-bottom: 30px;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      margin-top: 50px;
      background: $brand-secondary;
    }
    h3{
      font-size: 22px!important;
      font-family: "Frutiger LT W01_55 Roma1475738";
    font-weight: 400!important;
    line-height: 1.238;
    // color: inherit!important;
    text-transform: none!important;
    padding-bottom: 0;
    }
   
  }
  
  .deals{
    p{
      padding: 0;
    }
    h2{
      padding: 0;
    }
  }
/***** Custom CSS for Workshops Page Elements *****/
.workshop-page {
  .workshop-intro {
    p {
      font-size: 1em;
      margin-bottom: 0.333333em;
    }
    p {
      font-size: 1em;
      margin-bottom: 0.333333em;
    }
  }
  .workshop-content {
    padding: 0 0.5em 0.5em;
    border-left: solid 4px $color-course-dur;
    border-right: solid 4px $color-course-dur;
    border-radius: $panel-course-inner-radius;
    p {
      font-size: 0.85em;
      margin-bottom: 0.333333em;
    }
  }

  .workshop-intro, .workshop-content {
  	h1, h2, h4, h5, h6 {
  		margin-bottom: 0.5em;
  		text-align: center;
  		letter-spacing: 0.05rem;
  		line-height: 1.4;
  		color: darken($brand-primary, 10%);
  	}
  }
  .workshop-intro,
  .workshop-content {
    h3 {
      margin-bottom: 0.5em;
      // text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      line-height: 1.4;
      color: darken($brand-primary, 10%);
    }
  }

  .workshop-color-box {
    // padding: .5em .75em;
    padding: 2em;
    // height: 100%; /*full flex row height*/
    // background-color: $color-course-bg;
    border-radius: 0.5em;
    box-shadow: 0px 11px 24px -3px rgba(0, 0, 0, 0.41);
    // border-bottom: solid 2px darken($brand-primary, 10%);
    label {
      text-align: center;
    }
  }
  .card-01 {
    // min-height: 25vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
    text-align: start !important;
    align-items: start !important;
    border-radius: 0.5em;
    box-shadow: 0px 11px 24px -3px rgba(0, 0, 0, 0.41);
    ul,
    p {
      text-align: start !important;
      align-self: flex-start;
      width: 100%;
    }
    label {
      padding-bottom: 0.8em;
      text-align: start !important;
      align-self: flex-start;
      width: 100%;
      font-size: 1em;
      text-transform: uppercase;
      // font-weight: bold;
      letter-spacing: 0.025rem;
      color: darken($brand-primary, 10%) !important;
      font-family: "Frutiger LT W01_55 Roma1475738";
      font-weight: 400;
    }
  }

  .workshop-hook {
    padding: 0.5em 0.75em;
    height: 100%; /*full flex row height*/
    font-size: 0.9em;
    background-color: $color-course-bg;
    border-radius: 0.5em;
    border-bottom: solid 2px darken($brand-primary, 10%);
    display: -webkit-flex;
    display: flex;

    a {
      align-self: flex-end;
    }
  }

  .row-flex {
    /* flex layout to have columns with the same height
       NOTE: it is important that the columns include an xs class, so that their
       width is responsive on small devices where they are wrapped */
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap; /* make sure columns wrap properly */
  }
  .ws-cards {
    justify-content: space-around;

    @media (max-width: 768px) {
      justify-content: center;
      .me-3 {
        margin-top: 1em !important;
      }
    }
  }

  .workshop-box {
    // padding-top: 1.5em;

    /* distribute elements vertically inside each box */
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label:not(#ws-not-center) {
      width: 100%;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.025rem;
    }
    label.lbl-centered {
      text-align: center;
    }
    p {
      font-size: 1em;
    }
  }
}
.color-h3 {
  color: darken($brand-primary, 10%) !important;
}

/**** Custom CSS for lists *****/
ul.ws-list {
  li.closed {
    opacity: 0.5;
    text-decoration-line: line-through;
  }
}

/**** Custom CSS for Workshop series *****/
// ul.series-list {
//   margin: 20px 0;
//   padding-left: 0;
//   list-style: none;
//   li {
//     border-top: 1px solid $brand-secondary;
//     padding: 15px 0;
//     color: $brand-primary;
//     &:before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 17px;
//       background-image: url(../assets/images/chevron-right.svg);
//       background-size: cover;
//       margin-top: 7px;
//       color: inherit;
//       margin-right: 15px;
//     }
//     &:last-child {
//       border-bottom: 1px solid $brand-secondary;
//     }
//     a {
//     }
//   }
// }

//
//
// RIBBON
//
//
.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2674a8;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #008cc3;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 500 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;

  &::before,
  &::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }

  &::before {
    top: 0;
    right: 140px;
  }

  &::after {
    bottom: 0;
    right: 0;
  }

  & span {
    right: -55px;
    top: 35px;
    transform: rotate(45deg);
  }
}

.section-01 {
  &:last-child {
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
}

.back-link > a{
  text-decoration: none!important;
}

.modal-title{
  .member-name{
    color:#008cc3;
    font-size: 34px;
    margin: 0;
    font-weight: 400!important;
    line-height: normal;
    padding-bottom: 0;
  }
  h3{
    font-size: 22px;
    margin: 0;
    padding-bottom: 0;
    font-weight: 400!important;
    font-family: "Frutiger LT W01_55 Roma1475738";
    color: inherit;
    text-transform: unset;
  }
}
.modal-img-team{
  height: 40%;
  width: 65%!important;
  align-self: center;
  border-radius: 50%;
  padding: 10px 0 30px 0;
  margin: 0 auto;
  border: none!important;
}

button.close{
  float: right;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  // max-height: 30px!important;
  // max-width: 30px!important;
}
.button-modal-gallery{
  border: solid 0;
  padding: 0;
  margin: 0;
&:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
  background-color: #fff;
}}
button.btn-close{
  float: right;
  font-size: 15px!important;
  // text-transform: lowercase!important;
  // font-weight: 800!important;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  margin: 0;
  // max-height: 30px!important;
  // max-width: 30px!important;
}
.modal-header .btn-close{
  margin: 0 !important;
}