//
//
// FORMS
//
//

label {
    color: $primary;
}
.form-control {
    border-radius: 0 !important;
}
.required-label {
    &:after {
        content: "required";
        color: #bbb;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        font-size: 0.8rem;
        font-weight: normal;
    }
}
.form-error {
    border-color: #d62c2c !important;
}
.form-error-text {
    color: #d62c2c !important;
}
