// Navbar

//
//  Nav
//

#header-nav {
  padding: 0 50px 0 50px !important;
  &, & + #header-nav-padding {
    min-height: 90px;
  }
  @media (max-width: 768px) {
    padding: 0 30px 0 30px !important;
    &, & + #header-nav-padding {
      min-height: 70px;
    }
  }

  /* Scrolling transitions */

  transform: translateY(0);
  transition: transform 0.5s ease-out;

  &.slide-up {
    transform: translateY(-100%);
    transition: transform 0.5s ease-out;
  }

}
.overflow-y-0 {
  overflow: hidden !important;
}

.navbar {
  background: $white;
  padding: 0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  position: fixed !important;
  width: 100vw;
  z-index: 100;

  p,
  span,
  a {
    color: $title;

    color: #656263;
    color: #231f20;
  }

  .navbar-light {
    .nav-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      a {
        text-transform: uppercase;
      }
    }
  }

  .dropdown-item {
    color: $title;
    font-size: 14px;
    &:focus,
    &:hover {
      color: #252525;
      text-decoration: none;
    }
    &.active,
    &:active {
      color: #181818;
      text-decoration: none;
      background-color: $primary;
    }
  }

  .sm-menu {
    border-radius: 0px !important;
    border: 0px;
    top: 99.5% !important;
    // box-shadow: rgba(173, 173, 173, 0.2) 0px 0px 4px 1px;
    box-shadow: rgba(0, 0, 0, 0.192) 0px 0px 3px 0px;
    clip-path: inset(0px -4px -4px -4px);
  }

  .navbar-toggler {
    outline: none;
  }

  .nav-link {
    // border-bottom: 4px solid #ffffff00;
    transition: 0.3s;
    //   padding-left: 16px;
    //   padding-right: 16px;
    padding-left: calc(
      10px + (16 - 10) * ((100vw - 768px) / (1300 - 768))
    ) !important;
    padding-right: calc(
      10px + (16 - 10) * ((100vw - 768px) / (1300 - 768))
    ) !important;

    font-size: 14px;
    padding-top: 18px;
    padding-bottom: 8px;

    // &:focus,
    // &:hover {
    //   color: #1d1d1d;
    //   transition: all 0.6s ease;
    //   // border-bottom: 4px solid #008cc3;
    // }
  }

  // // //

  // Responsive

  // // //
  @media (max-width: 768px) {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 90px;

    .brand-div {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    .navbar-brand {
      height: 100% t;
      display: inline-flex;
      justify-self: center;
      margin: auto 0;
      // position: absolute;
      svg {
        // height: fit-content + 5px;
        width: 130px;
        z-index: 900;
      }
    }
  }
  // .borderRight {
  //   border-right: 1px solid $gray-400;
  // }
}

#header-nav,
#mobile_nav,
#header-nav > div:nth-child(1) {
  align-items: stretch;
}

.nav-item-after {
  position: relative;
}

.nav-link-after {
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: border-color 0.8s ease-in;
}

.nav-item-after:hover .nav-link-after {
  border-color: $primary;
}
.nav-item-after:active .nav-link-after {
  border-color: $primary;
}
.nav-item-after.active .nav-link-after {
  border-bottom: 3px solid $primary !important;
  box-sizing: border-box;
}

/* .navbar-tog{
    color: #1ebdc2;
} */
.megamenu-li {
  position: static !important;
}

.borderTop {
  position: relative;
  // border-top: 1px solid transparent;

  &::before {
    content: "";

    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.342);
  }
}
// Breakpoints for regular screens
$extra-small: 575px;
$small: 576px;
$small-medium: 667px;
$medium: 768px;
$medium-large: 900px;
$large: 992px;
$large-extra-large: 1100px;
$extra-large: 1200px;

// Breakpoints for retina screens (doubled pixel density)
$retina-extra-small: $extra-small * 2;
$retina-small: $small * 2;
$retina-small-medium: $small-medium * 2;
$retina-medium: $medium * 2;
$retina-medium-large: $medium-large * 2;
$retina-large: $large * 2;
$retina-large-extra-large: $large-extra-large * 2;
$retina-extra-large: $extra-large * 2;

.megamenu {
  position: absolute;
  max-width: 100% !important;
  background-color: white;
  // max-width: 100%;
  .dropdown-item {
    width: 100%;
  }
}

// // // //
//
// Mobile nav
//
// // // //



/* mobile menu hamburger button */
.showhide {
  display: flex;
  width: 46px;
  height: 46px;

  //   align-self: flex-end !important;

  @media (min-width: 768px) {
    display: none;
  }

  margin-top: auto;
  margin-bottom: auto;
  align-self: end;
  cursor: pointer;
  z-index: 800;

  &--inner {
    margin: 9px;
    position: relative;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    display: block;
  }
  span {
    position: absolute;
    background: $title;
    height: 2px;
    width: 28px;
    transition: 0.5s;
    opacity: 1;
    &.ham1 {
      top: 4px;
    }
    &.ham2 {
      top: 12px;
    }
    &.ham3 {
      top: 20px;
    }
  }
  .blue-bg {
    background: $white;
  }

  &.open {
    span {
      &.ham1 {
        transform: rotate(45deg);
        top: 14px;
      }
      &.ham2 {
        opacity: 0;
      }
      &.ham3 {
        transform: rotate(-45deg);
        top: 14px;
      }
    }
  }
}

/* mobile menu container */
.mobilenav {
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
  padding: 0;
  margin: 0;

  &--wrap {
    padding: 0;
    width: 100vw;
    height: 100vh;
    background: $primary;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    top: 0;
    right: -100vw;
    position: fixed;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &.show--mobilenav {
      right: 0;
    }
  }

  & > ul {
    width: 100%;
    box-sizing: border-box;
    // margin: 6rem 0 2rem 0 !important;
  }

  ul {
    ul {
      padding: 0 !important;
      li {
        position: relative;
        font-size: 90%;
        a {
          padding-left: 5rem !important;
        }
      }
    }
    li {
      position: relative;
      font-weight: 600;
      list-style: none;
      font-size: 18px;
      &:hover {
        ul {
          display: block;
          margin-left: 0;
        }
      }

      a {
        display: block;
        padding: 15px 20px;
        text-decoration: none;
        color: $white;

        &:hover:not(.menu-expand),
        &:active:not(.menu-expand) {
          background-color: #0f7299;
        }
        // &:hover .menu-expand,
        // &:active .menu-expand {
        //   background-color: #ffffff00 !important;
        // }
      }

      &.menu-item-has-children {
        .plus1 {
          content: "";
          position: absolute;
          right: 10px;
          top: 24px;
          width: 20px;
          height: 2px;
          display: block;
          background: #000;
          transition: all 0.3s ease-in-out;
          transform: rotate(-180deg);
        }
        .plus2 {
          content: "";
          position: absolute;
          right: 10px;
          top: 24px;
          width: 20px;
          height: 2px;
          display: block;
          background: #000;
          transition: all 0.3s ease-in-out;
          transform: rotate(-270deg);
        }
        & > a.menu-expand.menu-clicked .plus1 {
          transform: rotate(0);
        }

        & > a.menu-expand.menu-clicked .plus2 {
          transform: rotate(0);
        }
      }
    }
  }
}

a.menu-expand {
  padding: 0;
  background: transparent;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;

  span {
    background: $white !important;
  }
}

.mobilenav > ul {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.mobilenav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-mobile-nav {
  border-top: 0.5px solid $white;
  display: flex;
  justify-content: space-between;
  padding: 28px 50px !important;
  min-height: 90px;

  a {
    color: white;
    text-decoration: none;
    text-transform: capitalize;
  }
  span {
    a {
      padding-right: 12px !important;
    }
  }
}

.top-mobile-nav {
  //   padding-left: 30px;
  //   padding-right: 30px;
  padding-bottom: 28px !important;
  border-bottom: 0.5px solid $white;
  min-height: 90px;
  // position: absolute;

  background-color: $primary;
  z-index: 800;
}

li.menu-item-has-children > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

li.menu-item-has-children > a.hovered {
  background-color: #0f7299; /* Replace with your desired background color */
}

//  adds color to logo in nav sidebar
.path-color-mobile {
  fill: #fff;
}

.menu-ul {
  overflow-y: auto;
  margin: 0;
  li {
    a {
      padding-left: 3em !important;
      padding-right: 3em !important;
    }
    .menu-expand {
      transform: translateX(-1.8em) !important;
      // margin-top: 4px !important;
    }
  }
}

.top-hidden {
  z-index: 901 !important;
  position: absolute !important;
  content: "" !important;

  background-color: #000;
  min-height: 90px !important;
}

#mobile_nav {
  z-index: 900;
  // padding-right: 50px !important;
  justify-content: end !important;
}
.menu-expand {
  &,
  &:hover,
  &:active {
    background-color: #00000000 !important;
  }
}



.padding-left-nav {
  // a,
  // h6 {
  //   padding-left: 2rem !important;
  // }
  // h6 {
  //   margin-left: 0 !important;
  // }
}
